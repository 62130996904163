import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router)

const page = path => () => import(`~/pages/${path}`).then(m => m.default || m);

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: [
      { path: '/', name: 'index', component: page('index.vue') },
      { path: '/login', name: 'login', component: page('auth/login.vue') },
      { path: '/register', name: 'register', component: page('auth/register.vue') },
      { path: '/auth/social/callback', name: 'social.login', component: page('auth/social/callback.vue') },
      {
        path: '/verification/verify/:id',
        name: 'verify',
        component: page('auth/verification/verify.vue')
      },
      {
        path: '/verification/resend',
        name: 'verification.resend',
        component: page('auth/verification/resend.vue')
      },
      {
        path: '/password/forgot',
        name: 'password.forgot',
        component: page('auth/password/forgot.vue')
      },
      {
        path: '/password/reset/:token',
        name: 'password.reset',
        component: page('auth/password/reset.vue')
      },
      {
        path: '/settings',
        component: page('user/settings/index.vue'),
        children: [
          { path: '', redirect: { name: 'settings.profile' } },
          {
            path: 'profile',
            name: 'settings.profile',
            component: page('user/settings/profile.vue')
          },
          {
            path: 'password',
            name: 'settings.password',
            component: page('user/settings/password.vue')
          }
        ]
      },
      {
        path: '/blog',
        name: 'public.blog',
        component: page('public/blog.vue')
      },
      {
        path: '/contact',
        name: 'public.contact',
        component: page('public/contact.vue')
      },
      {
        path: '/search/:query',
        name: 'public.search',
        component: page('public/search.vue')
      },
      {
        path: '/:slug',
        name: 'public.page',
        component: page('public/page.vue')
      },
      {
        path: '/blog/:slug',
        name: 'public.post',
        component: page('public/post.vue')
      },
      {
        path: '/category/:slug',
        name: 'public.tool_category',
        component: page('public/tool_category.vue')
      },
      {
        path: '/blog/category/:slug',
        name: 'public.post_category',
        component: page('public/post_category.vue')
      },
      {
        path: '/admin',
        component: page('admin/index.vue'),
        children: [
          { path: '', redirect: { name: 'admin.users.list' } },
          {
            path: 'users',
            name: 'admin.users.list',
            component: page('admin/users/list.vue')
          },
          //Post Categories
          {
            path: 'posts/categories',
            name: 'admin.posts.categories.list',
            component: page('admin/posts/categories/list.vue')
          },
          {
            path: 'posts/categories/create',
            name: 'admin.posts.categories.create',
            component: page('admin/posts/categories/create.vue')
          },
          {
            path: 'posts/categories/edit/:id',
            name: 'admin.posts.categories.edit',
            component: page('admin/posts/categories/edit.vue')
          },
          //Posts
          {
            path: 'posts',
            name: 'admin.posts.list',
            component: page('admin/posts/list.vue')
          },
          {
            path: 'posts/create',
            name: 'admin.posts.create',
            component: page('admin/posts/create.vue')
          },
          {
            path: 'posts/edit/:id',
            name: 'admin.posts.edit',
            component: page('admin/posts/edit.vue')
          },
          //Pages
          {
            path: 'pages',
            name: 'admin.pages.list',
            component: page('admin/pages/list.vue')
          },
          {
            path: 'pages/create',
            name: 'admin.pages.create',
            component: page('admin/pages/create.vue')
          },
          {
            path: 'pages/edit/:id',
            name: 'admin.pages.edit',
            component: page('admin/pages/edit.vue')
          },
          //Tool Categories
          {
            path: 'tools/categories',
            name: 'admin.tools.categories.list',
            component: page('admin/tools/categories/list.vue')
          },
          {
            path: 'tools/categories/create',
            name: 'admin.tools.categories.create',
            component: page('admin/tools/categories/create.vue')
          },
          {
            path: 'tools/categories/edit/:id',
            name: 'admin.tools.categories.edit',
            component: page('admin/tools/categories/edit.vue')
          },
          //Tools
          {
            path: 'tools',
            name: 'admin.tools.list',
            component: page('admin/tools/list.vue')
          },
          {
            path: 'tools/create',
            name: 'admin.tools.create',
            component: page('admin/tools/create.vue')
          },
          {
            path: 'tools/edit/:id',
            name: 'admin.tools.edit',
            component: page('admin/tools/edit.vue')
          },
        ]
      },
    ],
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
  })
}