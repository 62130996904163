
import IconLight from '@/assets/images/light.svg?inline'
import IconDark from '@/assets/images/dark.svg?inline'
import IconSystem from '@/assets/images/system.svg?inline'
import LogoLight from '~/assets/images/logo-light.svg'
import LogoDark from '~/assets/images/logo-dark.svg'

export default {
  components: {
    IconLight,
    IconDark,
    IconSystem
  },
  data() {
    return {
    	LogoLight,
    	LogoDark,
      tools: [],
      searching: false,
      filters: {
        q: ''
      }
    }
  },
  computed: {
    queryString() {
      return Object.keys(this.filters)
        .map(k => `${this.filters[k]}`);
    }
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    toggleColorMode(color){
    	if ( color === 'light') { this.$colorMode.preference = 'dark' } else { this.$colorMode.preference = 'light' }
    },
    search() {
    	this.searching = true;
      this.$axios.$get(`/quick-search/${this.queryString}`, { progress: false })
        .then(res => { 
        	this.tools = res;
        })
        .catch(e => { 
			     this.$toast.error(e.response.data.message, { 'position': 'top-right' })
        })
        .finally(() => {
        	this.searching = false;
        });
    }
  	//
  }
};
