
import SiteFooter from './partials/public/footer'

export default {
  props: ['error'],
  components:{
    SiteFooter
  },
  head() {
    return {
      title: `${ this.error.statusCode } | ${this.$config.siteName}`,
      meta: [
        { hid: 'title', name: 'robots', content: 'follow, noindex' },
      ]
    }
  },
}
