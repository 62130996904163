export const ToolsAgeCalculator = () => import('../../components/tools/AgeCalculator.vue' /* webpackChunkName: "components/tools-age-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsAgeDifferenceCalculator = () => import('../../components/tools/AgeDifferenceCalculator.vue' /* webpackChunkName: "components/tools-age-difference-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsBinaryCalculator = () => import('../../components/tools/BinaryCalculator.vue' /* webpackChunkName: "components/tools-binary-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsBirthdayCalculator = () => import('../../components/tools/BirthdayCalculator.vue' /* webpackChunkName: "components/tools-birthday-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsBmiCalculator = () => import('../../components/tools/BmiCalculator.vue' /* webpackChunkName: "components/tools-bmi-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsBmrCalculator = () => import('../../components/tools/BmrCalculator.vue' /* webpackChunkName: "components/tools-bmr-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsBodyFatCalculator = () => import('../../components/tools/BodyFatCalculator.vue' /* webpackChunkName: "components/tools-body-fat-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsCalorieCalculator = () => import('../../components/tools/CalorieCalculator.vue' /* webpackChunkName: "components/tools-calorie-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsCpmCalculator = () => import('../../components/tools/CpmCalculator.vue' /* webpackChunkName: "components/tools-cpm-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsDaysCalculator = () => import('../../components/tools/DaysCalculator.vue' /* webpackChunkName: "components/tools-days-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsDiscountCalculator = () => import('../../components/tools/DiscountCalculator.vue' /* webpackChunkName: "components/tools-discount-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsFaqSchemaGenerator = () => import('../../components/tools/FaqSchemaGenerator.vue' /* webpackChunkName: "components/tools-faq-schema-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsFlipImage = () => import('../../components/tools/FlipImage.vue' /* webpackChunkName: "components/tools-flip-image" */).then(c => wrapFunctional(c.default || c))
export const ToolsGstCalculator = () => import('../../components/tools/GstCalculator.vue' /* webpackChunkName: "components/tools-gst-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsHealthyWeightCalculator = () => import('../../components/tools/HealthyWeightCalculator.vue' /* webpackChunkName: "components/tools-healthy-weight-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsHexCalculator = () => import('../../components/tools/HexCalculator.vue' /* webpackChunkName: "components/tools-hex-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsHexadecimalToOctal = () => import('../../components/tools/HexadecimalToOctal.vue' /* webpackChunkName: "components/tools-hexadecimal-to-octal" */).then(c => wrapFunctional(c.default || c))
export const ToolsHoursCalculator = () => import('../../components/tools/HoursCalculator.vue' /* webpackChunkName: "components/tools-hours-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsIcoConverter = () => import('../../components/tools/IcoConverter.vue' /* webpackChunkName: "components/tools-ico-converter" */).then(c => wrapFunctional(c.default || c))
export const ToolsImageCropper = () => import('../../components/tools/ImageCropper.vue' /* webpackChunkName: "components/tools-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const ToolsImageEnlarger = () => import('../../components/tools/ImageEnlarger.vue' /* webpackChunkName: "components/tools-image-enlarger" */).then(c => wrapFunctional(c.default || c))
export const ToolsImageResizer = () => import('../../components/tools/ImageResizer.vue' /* webpackChunkName: "components/tools-image-resizer" */).then(c => wrapFunctional(c.default || c))
export const ToolsJpgToPng = () => import('../../components/tools/JpgToPng.vue' /* webpackChunkName: "components/tools-jpg-to-png" */).then(c => wrapFunctional(c.default || c))
export const ToolsMarginCalculator = () => import('../../components/tools/MarginCalculator.vue' /* webpackChunkName: "components/tools-margin-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsMonthCalculator = () => import('../../components/tools/MonthCalculator.vue' /* webpackChunkName: "components/tools-month-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsOctalToHexadecimal = () => import('../../components/tools/OctalToHexadecimal.vue' /* webpackChunkName: "components/tools-octal-to-hexadecimal" */).then(c => wrapFunctional(c.default || c))
export const ToolsOctalToText = () => import('../../components/tools/OctalToText.vue' /* webpackChunkName: "components/tools-octal-to-text" */).then(c => wrapFunctional(c.default || c))
export const ToolsPercentageCalculator = () => import('../../components/tools/PercentageCalculator.vue' /* webpackChunkName: "components/tools-percentage-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsPngToJpg = () => import('../../components/tools/PngToJpg.vue' /* webpackChunkName: "components/tools-png-to-jpg" */).then(c => wrapFunctional(c.default || c))
export const ToolsRandomGuidGenerator = () => import('../../components/tools/RandomGuidGenerator.vue' /* webpackChunkName: "components/tools-random-guid-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsRandomLetterPicker = () => import('../../components/tools/RandomLetterPicker.vue' /* webpackChunkName: "components/tools-random-letter-picker" */).then(c => wrapFunctional(c.default || c))
export const ToolsRandomListPicker = () => import('../../components/tools/RandomListPicker.vue' /* webpackChunkName: "components/tools-random-list-picker" */).then(c => wrapFunctional(c.default || c))
export const ToolsRandomUuidGenerator = () => import('../../components/tools/RandomUuidGenerator.vue' /* webpackChunkName: "components/tools-random-uuid-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsRotateImage = () => import('../../components/tools/RotateImage.vue' /* webpackChunkName: "components/tools-rotate-image" */).then(c => wrapFunctional(c.default || c))
export const ToolsSalesTaxCalculator = () => import('../../components/tools/SalesTaxCalculator.vue' /* webpackChunkName: "components/tools-sales-tax-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsSha1HashGenerator = () => import('../../components/tools/Sha1HashGenerator.vue' /* webpackChunkName: "components/tools-sha1-hash-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsStrikeTextGenerator = () => import('../../components/tools/StrikeTextGenerator.vue' /* webpackChunkName: "components/tools-strike-text-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsTdeeCalculator = () => import('../../components/tools/TdeeCalculator.vue' /* webpackChunkName: "components/tools-tdee-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsTextRepeaterGenerator = () => import('../../components/tools/TextRepeaterGenerator.vue' /* webpackChunkName: "components/tools-text-repeater-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsTextSorter = () => import('../../components/tools/TextSorter.vue' /* webpackChunkName: "components/tools-text-sorter" */).then(c => wrapFunctional(c.default || c))
export const ToolsTimecardCalculator = () => import('../../components/tools/TimecardCalculator.vue' /* webpackChunkName: "components/tools-timecard-calculator" */).then(c => wrapFunctional(c.default || c))
export const ToolsWebpToJpg = () => import('../../components/tools/WebpToJpg.vue' /* webpackChunkName: "components/tools-webp-to-jpg" */).then(c => wrapFunctional(c.default || c))
export const ToolsYoutubeChannelBannerDownloader = () => import('../../components/tools/YoutubeChannelBannerDownloader.vue' /* webpackChunkName: "components/tools-youtube-channel-banner-downloader" */).then(c => wrapFunctional(c.default || c))
export const ToolsYoutubeChannelId = () => import('../../components/tools/YoutubeChannelId.vue' /* webpackChunkName: "components/tools-youtube-channel-id" */).then(c => wrapFunctional(c.default || c))
export const ToolsYoutubeChannelLogoDownloader = () => import('../../components/tools/YoutubeChannelLogoDownloader.vue' /* webpackChunkName: "components/tools-youtube-channel-logo-downloader" */).then(c => wrapFunctional(c.default || c))
export const ToolsYoutubeEmbedCodeGenerator = () => import('../../components/tools/YoutubeEmbedCodeGenerator.vue' /* webpackChunkName: "components/tools-youtube-embed-code-generator" */).then(c => wrapFunctional(c.default || c))
export const ToolsYoutubeRegionRestrictionChecker = () => import('../../components/tools/YoutubeRegionRestrictionChecker.vue' /* webpackChunkName: "components/tools-youtube-region-restriction-checker" */).then(c => wrapFunctional(c.default || c))
export const ToolsYoutubeThumbnailDownloader = () => import('../../components/tools/YoutubeThumbnailDownloader.vue' /* webpackChunkName: "components/tools-youtube-thumbnail-downloader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
