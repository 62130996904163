const middleware = {}

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isadmin'] = require('../middleware/isadmin.js')
middleware['isadmin'] = middleware['isadmin'].default || middleware['isadmin']

middleware['redirect-slash'] = require('../middleware/redirect-slash.js')
middleware['redirect-slash'] = middleware['redirect-slash'].default || middleware['redirect-slash']

export default middleware
