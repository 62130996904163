
import SiteNavbar from './partials/public/navbar'
import SiteFooter from './partials/public/footer'

export default {
  components:{
    SiteNavbar,
    SiteFooter
  }

}
